.scroll {
  -ms-overflow-style: none;
}

.scroll::-webkit-scrollbar {
  display: none;
}

@-moz-document url-prefix() {
  .scroll {
    scrollbar-width: none;
  }
}
