.truncate_after_two_lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Standard fallback for non-WebKit browsers */
  display: -moz-box;
  -moz-box-orient: vertical;
  max-height: 3em;
  /* Approximate height of two lines of text */
}

.truncate_after_three_lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Standard fallback for non-WebKit browsers */
  display: -moz-box;
  -moz-box-orient: vertical;
  /* Approximate height of two lines of text */
}
.truncate_after_single_line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Standard fallback for non-WebKit browsers */
  display: -moz-box;
  -moz-box-orient: vertical;
  max-height: 1.5em;
  /* Approximate height of two lines of text */
}
.multiline_truncate {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Standard fallback for non-WebKit browsers */
  display: -moz-box;
  -moz-box-orient: vertical;
  max-height: 9em;
  /* Approximate height of two lines of text */
}
